
import { Link } from "react-scroll";

/*--------------------
* About Section
----------------------*/
export default function About() {
    return (
        <>
            <section className="section">
                <div className="container">
                    <div className="grid lg:grid-cols-12 grid-cols-1 items-center">
                        <div className="lg:col-span-6 text-center mb-[50px] lg:mb-0">
                            <img className="mx-auto" src="img/aboutme-banner.png" title="Banner" alt="Banner" />
                        </div>
                        <div className="lg:col-span-6 lg:pl-12">
                            <h3 className="font-[600] text-[26px] md:text-[40px] leading-[1.2] text-black mb-[25px]">I am a DevOps Engineer with over 8 years of experience.</h3>
                            <p className="text-[16px] md:text-[18px]">I am a DevOps Engineer with over 8 years of experience.. I'm from Dhaka. I develop and manage efficient, scalable systems. I like work with new people. New people new Experiences.</p>
                            <div className="grid grid-cols-12 pt-5">
                            </div>
                            <div className="pt-6">
                            <a className="px-btn px-btn-theme mr-4" href="mailto:me@amdadulbari.com">Contact Me</a>
                                <a className="px-btn px-btn-dark" href="https://medium.com/amdadul-bari-imad" target="_blank" rel="noopener noreferrer">Blog</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
